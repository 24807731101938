<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<b-col cols="12" xl="6" md="6">
				<sales-all-sellers :filterBy="filterBy" :refresh="false" />
			</b-col>
			<b-col cols="12" xl="6" md="6">
				<sales-disccounts />
			</b-col>
		</b-row>


		<!-- <b-row class="row match-height">
			<b-col cols="12" xl="4" md="4">
				<sale-goals />
			</b-col>
		</b-row> -->

		
	</section>
</template>

<script>

import {
	BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
	BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BFormRadio, BFormRadioGroup, BFormTextarea, BFormInvalidFeedback,
	BButton,
} from 'bootstrap-vue'
import store from '@/store';
import Swal from "sweetalert2";
import "animate.css";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment';

import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import SalesAllSellers from '@/@core/components/sellers/SalesAllSellers.vue';
import SalesDisccounts from '@/@core/components/SalesDisccounts.vue';
import SaleGoals from '@/@core/components/SaleGoals.vue';
import DashboardsProvider from '@/providers/Dashboards'

const DashboardsResource = new DashboardsProvider()

export default {
	name: 'DashboardAdministrator',
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BFormGroup, 
		BFormInput, 
		BFormSelect, 
		BFormCheckbox, 
		BFormRadio, 
		BFormRadioGroup, 
		BFormTextarea, 
		BFormInvalidFeedback,
		BButton,

		//
		SalesAllSellers,
		SalesDisccounts,
		SaleGoals,

		//
		flatPickr,
        vSelect,
	},
	mixins: [heightTransition],
	setup() {

		const loading = ref(false)
		const filterSearch = ref({
			fechaIni: moment().format('YYYY-MM-DD'),
			fechaFin: moment().format('YYYY-MM-DD'),
			tipoBitacora: null,
		})

		const filterBy = computed(() => {
			const filter = store.getters['auth/getFilterOnDashboardSeller']
			const seller_id = store.getters['auth/getSellerId']
			return {
				...filter,
				vendedor_Id: seller_id
			}
		})

		return {
			loading,
			filterSearch,
			filterBy,
		}	

	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<style>
.match-height>[class*=col] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}
</style>